
import { Contract } from '@ethersproject/contracts'
import { getRouterContract } from '../utils'
import { useActiveWeb3React } from './index'
import { useState, useEffect } from 'react';

export function useInviteNumber(): string {
    const { account, chainId, library } = useActiveWeb3React();
    const [inviteCount, setInviteCount] = useState('0');

    useEffect(() => {
        if (!library || !account || !chainId) return;

        const contract: Contract | null = getRouterContract(chainId, library, account);
        if (!contract) {
            setInviteCount('0');
            return;
        }

        contract['getDirectReferralCount'](account)
            .then((response: any) => {
                console.log('response==', response.toString());
                setInviteCount(response.toString());  // 更新状态
            })
            .catch((error: any) => {
                console.error(`Swap failed`, error);
                throw new Error(`Swap failed: ${error.message}`);
            });

    }, [account, chainId, library]);

    return inviteCount; // 返回最新的直推人数
}

export function useInviteTwoNumber(): string {
    const { account, chainId, library } = useActiveWeb3React();
    const [inviteCount, setInviteCount] = useState('0');

    useEffect(() => {
        if (!library || !account || !chainId) return;

        const contract: Contract | null = getRouterContract(chainId, library, account);
        if (!contract) {
            setInviteCount('0');
            return;
        }

        contract['getIndirectReferralCount'](account)
            .then((response: any) => {
                console.log('response==', response.toString());
                setInviteCount(response.toString());  // 更新状态
            })
            .catch((error: any) => {
                console.error(`Swap failed`, error);
                throw new Error(`Swap failed: ${error.message}`);
            });

    }, [account, chainId, library]);

    return inviteCount; // 返回最新的获取间推人数
}
